import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import main from './assets/style/main.scss'
// import VueHead from 'vue-head'
Vue.config.productionTip = false

new Vue({
  // VueHead,
  router,
  store,
  main,
  render: h => h(App)
}).$mount('#app')
