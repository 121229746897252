const downData = {
    title: '蜂鸟电竞',
    logoIcon: '',
    downloadLink: [
        {
            name:'Google Play',
            icon: 'google_play_app_nav_unsel.png',
            icon_hover:'google_play_app_btn.png',
            url: ''
        },
        {
            name:'Android',
            icon: 'icon_android_hd.png',
            icon_hover:'icon_android_hd_active.png',
            url: ''
        },
        {
            name:'iPhone',
            icon: 'icon_ios_hd.png',
            icon_hover:'icon_ios_hd_active.png',
            url: ''
        },
        {
            name:'蜂鸟电竞官网',
            icon: '',
            icon_hover:'',
            url: ''
        }
    ],
    downloadDesc:'https://qn.tianqifengyun.cn/pcweb/app_download_text_001.png',
    downloadScanCode: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png',
    
}

const allTdk = {
    def: {
      title: { inner:'蜂鸟竞技APP下载-蜂鸟电竞APP-足球篮球直播软件-电竞比分APP-蜂鸟竞技iOS安卓版' },
        meta: [
          { name: 'description', hid: 'description', content: '蜂鸟竞技APP是蜂鸟竞技研发的手机移动端体育电竞赛事直播软件，包含iOS苹果版和安卓版下载。专注于足球比分直播、篮球比分直播、电竞比分直播，给您极速电竞体育数据和畅快动画直播体验。' },
          { name: 'keywords', hid: 'keywords', content: '蜂鸟竞技,蜂鸟竞技APP,蜂鸟电竞,蜂鸟电竞APP,足球比分,足球直播,篮球比分,篮球直播,电竞比分,电竞直播,iOS下载,安卓下载' }
        ]
    },
    huya: {
      title: { inner:'蜂鸟竞技APP下载-蜂鸟电竞APP-足球篮球直播软件-电竞比分APP-蜂鸟竞技iOS安卓版' },
        meta: [
          { name: 'description', hid: 'description', content: '蜂鸟竞技APP是蜂鸟竞技研发的手机移动端体育电竞赛事直播软件，包含iOS苹果版和安卓版下载。专注于足球比分直播、篮球比分直播、电竞比分直播，给您极速电竞体育数据和畅快动画直播体验。' },
          { name: 'keywords', hid: 'keywords', content: '蜂鸟竞技,蜂鸟竞技APP,蜂鸟电竞,蜂鸟电竞APP,足球比分,足球直播,篮球比分,篮球直播,电竞比分,电竞直播,iOS下载,安卓下载' }
        ]
    },
    douyu: {
      title: { inner:'蜂鸟竞技APP下载-蜂鸟电竞APP-足球篮球直播软件-电竞比分APP-蜂鸟竞技iOS安卓版' },
        meta: [
          { name: 'description', hid: 'description', content: '蜂鸟竞技APP是蜂鸟竞技研发的手机移动端体育电竞赛事直播软件，包含iOS苹果版和安卓版下载。专注于足球比分直播、篮球比分直播、电竞比分直播，给您极速电竞体育数据和畅快动画直播体验。' },
          { name: 'keywords', hid: 'keywords', content: '蜂鸟竞技,蜂鸟竞技APP,蜂鸟电竞,蜂鸟电竞APP,足球比分,足球直播,篮球比分,篮球直播,电竞比分,电竞直播,iOS下载,安卓下载' }
        ]
    },
    sougos: {
      title: { inner:'蜂鸟竞技APP下载-蜂鸟电竞APP-足球篮球直播软件-电竞比分APP-蜂鸟竞技iOS安卓版' },
        meta: [
          { name: 'description', hid: 'description', content: '蜂鸟竞技APP是蜂鸟竞技研发的手机移动端体育电竞赛事直播软件，包含iOS苹果版和安卓版下载。专注于足球比分直播、篮球比分直播、电竞比分直播，给您极速电竞体育数据和畅快动画直播体验。' },
          { name: 'keywords', hid: 'keywords', content: '蜂鸟竞技,蜂鸟竞技APP,蜂鸟电竞,蜂鸟电竞APP,足球比分,足球直播,篮球比分,篮球直播,电竞比分,电竞直播,iOS下载,安卓下载' }
        ]
    }
  }

  const scanInfo = {
      def: {
        downloadTitle: 'https://qn.tianqifengyun.cn/pcweb/app_download_text_001.png',
        downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png'
      },
      huya: {
        downloadTitle: 'https://qn.tianqifengyun.cn/pcweb/app_download_text_001.png',
        downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png'
      },
      douyu: {
        downloadTitle: 'https://qn.tianqifengyun.cn/pcweb/app_download_text_001.png',
        downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png'
      },
      sougos: {
        downloadTitle: 'https://qn.tianqifengyun.cn/pcweb/app_download_text_001.png',
        downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png'
      },
  }

  export {
     downData,
      allTdk,
      scanInfo
  }