<template>
    <div class="nav-wrap fn-flex-col">
        <div class="nav-content fn-flex-row">
            <img src="https://qn.tianqifengyun.cn/pcweb/header_white_font_logo_001.png" alt="">
            <div class="nav-btn-panel fn-flex-row">
                <a href="https://www.fnscore.cn/" class="nav-btn-back fn-flex-row" target="_black">
                    <img src="https://qn.tianqifengyun.cn/download/download_icon_backHome.png" alt="">
                     蜂鸟竞技官网
                </a>
                <div class="nav-btn-list fn-flex-row">
                    <div class="nav_list" v-for="(item,index) in navList" :key="index"><a :href="item.link" target="_black">{{item.title}}</a></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FnDownloadNav',
    data: () => ({
        navList: [
            {
                title: '足球',
                link: 'https://www.fnscore.cn/schedule-football.html'
            },
            {
                title: '篮球',
                link: 'https://www.fnscore.cn/schedule-basketball.html'
            },
            {
                title: '英雄联盟',
                link: 'https://www.fnscore.cn/schedule-lol.html'
            },
            {
                title: 'DOTA2',
                link: 'https://www.fnscore.cn/schedule-dota2.html'
            },
            {
                title: 'CS:GO',
                link: 'https://www.fnscore.cn/schedule-csgo.html'
            },
            {
                title: '王者荣耀',
                link: 'https://www.fnscore.cn/schedule-kog.html'
            }
        ]
    })
}
</script>
<style lang="scss" scoped>
.nav-content{
    width: 1200px;
    height: 98px;
    justify-content: space-between;
    img{
        max-width: 162px;
        max-height: 46px;
    }
}
.nav-btn-panel{
    width: 984px;
    height: 98px;
    justify-content: flex-end;
}
.nav-btn-back{
    font-size: 14px;
    color: #FFBF34;
    cursor: pointer;
    width: 108px;
    height: 16px;
    img{
        margin-right: 8px;
        max-width: 16px;
        max-height: 16px;
    }
    margin-right: 38px;
}
.nav-btn-list .nav_list{
    font-size: 14px;
    color: #fff;
    width: 80px;
    border-left: 2px solid #999;
    transform: skewX(-15deg);
    a{  
        display: block;
        font-size: 14px;
        color: #fff;
        transform: skewX(15deg);
    }
    
}
.nav-btn-list div:first-child{
        border:none;
    }



.nav-btn-google{
    cursor: pointer;
    width: 206px;
    height: 52px;
    background: url('https://qn.tianqifengyun.cn/pcweb/google_play_app_nav_unsel.png');
    background-size: 100% 100%;
    &:hover{
    background: url('https://qn.tianqifengyun.cn/pcweb/google_play_app_btn.png');
    background-size: 100% 100%;
    }
}
.nav-btn-phone{
    margin-left: 15px;
    cursor: pointer;
    width: 160px;
    height: 52px;
    text-align: center;
    font-size: 20px;
    color: #FAA700;
    border: 1px solid #FAA700;
    border-radius: 26px;
    .phone-icon-android{
        width: 36px;
        height: 36px;
        margin-right: 4px;
        margin-bottom: 2px;
        background: url('https://qn.tianqifengyun.cn/pcweb/icon_android_hd.png');
        background-size: 100% 100%;
    }
    .phone-icon-iphone{
        width: 36px;
        height: 36px;
        margin-right: 4px;
        margin-bottom: 2px;
        background: url('https://qn.tianqifengyun.cn/pcweb/icon_ios_hd.png');
        background-size: 100% 100%;
    }
    &:hover{
        background: #FAA700;
        color: #ffffff;
        .phone-icon-android{
        background: url('https://qn.tianqifengyun.cn/pcweb/icon_android_hd_active.png');
        background-size: 100% 100%;
        }
        .phone-icon-iphone{
        background: url('https://qn.tianqifengyun.cn/pcweb/icon_ios_hd_active.png');
        background-size: 100% 100%;
        }
    }
}
</style>
