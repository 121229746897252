import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
// import { component } from 'vue/types/umd'
import Home from '../views/index.vue'
import AdPage from '../views/ad.vue'

Vue.use(VueHead)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inv.html',
    name: 'inv',
    component: Home
  },
  {
    path: '/ad.html',
    name: 'ad',
    component: AdPage
  },
  {
    path: '/:id',
    name: 'Detail',
    component:() => import(/* webpackChunkName: "about" */ '../views/index.vue')
  }
  // ,
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
