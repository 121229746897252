<template>
  <div>
    <div class="home-wrapper fn-flex-col">
      <fn-download-nav/>
      <div class="download-content">
        <div class="download-content-left fn-flex-col">
            <!-- 顶部描述 -->
           <div class="download-content-desc fn-flex-row">
             <div class="division-bar"></div>
             <div class="download-desc-bar">下载蜂鸟竞技APP , 领取您的专属方案</div>
             <div class="division-bar"></div>
           </div>
           <!-- 中间图片文字 -->
           <img class="download-slogan" src="https://qn.tianqifengyun.cn/download/download_left_slogan.png" width="594px" height="95px" alt="">
           <!-- 二维码和按钮 -->
           <div class="download-panel-group fn-flex-row">
             <div class="download-panel">
              <img src="https://qn.tianqifengyun.cn/download/download_scanImg.png" alt="">
              <!-- <p>手机浏览器扫码下载</p> -->
             </div>
             <div class="download-link-group fn-flex-col">
                <a :href="items.link" class="fn-flex-row" v-for="(items,index) in linkBtn" :key="index">
                  <img :src="'https://qn.tianqifengyun.cn/download/'+items.icon" alt="">
                  <span>{{items.btnName}}</span>
                </a>
             </div>
           </div>
           
        </div>
        <div class="download-content-right">
          <img src="https://qn.tianqifengyun.cn/download/download_right_applePanel_001.png" alt="">
        </div>
      </div>
    </div>
    <div class="home-footer fn-flex-col">
      <p>声明：本体育比分直播产品严格遵守国家法律相关规定，提供的体育赛事数据，统计数据，资讯分析等相关信息仅供体育爱好者浏览参考之用，禁止任何人非法滥用，否则责任自负，且与本网站无任何关系。 请认准蜂鸟竞技官</p>
      <p>方域名：www.fnscore.cn，其他冒用“蜂鸟竞技”品牌的站点均与本网站无任何关系，我们将保留追诉的权利。本站视频源来自热心网友自发补充，如有侵犯您的权益，请联系我们删除。</p>
      <p>Copyright © 2019-2024 fnscore.cn All Rights Reserved</p>
    </div>
  </div>
</template>
<script>
import { allTdk, scanInfo } from '@/config/indexData.js'
import FnDownloadNav from '@/components/FnDownloadNav.vue'
// const nowData = {
//   downloadTitle: 'https://qn.tianqifengyun.cn/download/download_left_slogan.png',
//   downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png',
//   downloadRight: 'https://qn.tianqifengyun.cn/download/download_right_applePanel.png'
// }
export default {
    name:'home',
    components: {
      FnDownloadNav
    },
    data: () => ({
      allTdk: allTdk,
      allImg: {
        downloadTitle: 'https://qn.tianqifengyun.cn/download/download_left_slogan.png',
        downloadScan: 'https://qn.tianqifengyun.cn/pcweb/app_event_qrcode_002.png',
        downloadRight: 'https://qn.tianqifengyun.cn/download/download_right_applePanel.png',

      },
      linkBtn: [
        {
          icon: 'download_icon_android.png',
          btnName: 'Android版',
          link: 'https://app.fnscore.cn/ApkDownload'
        },
        {
          icon: 'download_icon_apple.png',
          btnName: 'iPhone版',
          link: 'https://apps.apple.com/cn/app/id1537504237'
        }
      ]
    }),
    created(){
      console.log(this.$route.path,this.allTdk['def'])
    },
    mounted(){
      console.log(this.downloadInfo, '1111')
    },
    head(){
      console.log(this.allTdk['def'])
      if(this.$route.path){
        if(this.$route.path === '/huya'){
          this.downloadInfo = scanInfo['huya']
          return this.allTdk['huya']
        }else if(this.$route.path === '/douyu'){
          this.downloadInfo = scanInfo['douyu']
          return this.allTdk['douyu']
        }else if(this.$route.path === '/sogous'){
          this.downloadInfo = scanInfo['sogous']
          return this.allTdk['sogous']
        }else{
          this.downloadInfo = scanInfo['def']
          return this.allTdk['def']
        }
      }
      
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.home-wrapper {
  margin: 0 auto;
  height: calc(100vh - 70px);
  background: url('https://qn.tianqifengyun.cn/pcweb/bg_img.png');
  // background-size: 100% 100%;
  background-size: cover;
	background-position: center;
  justify-content: flex-start;
  position: relative;
	overflow: hidden;
}
.download-content{
  position:absolute;
  top: 160px;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.canvas-thunder-left{
  position: absolute;
	display: inline-block;
	width: 426px;
	height: 641px;
	left: 0px;
}
.canvas-thunder-right{
  position: absolute;
	display: inline-block;
	width: 426px;
	height: 641px;
	right: 0px;
}
.ui-left-sdian {
	transform: scale(1.4);
}

.download-content-left{
  width: 594px;
  justify-content: flex-start;
  // height: 533px;
  .download-content-desc{
    width: 564px;
    height: 25px;
    margin-top: 30px;
    justify-content: flex-start;
  }
  .download-slogan{
    width: 564px;
    height: 140px;
    margin-top: 15px;
  }
  .download-panel-group{
    width: 564px;
    margin-top: 50px;
    justify-content: flex-start;
  }
}
.download-desc-bar{
  font-size: 20px;
  color:#fff;
}
.division-bar{
  width: 42px;
  height: 1px;
  background: #FFFFFF;
  opacity: 0.4;
  margin-left: 12px;
  margin-right: 12px;
}
.download-panel{
  padding: 24px;
  width: 208px;
  height: 208px;
  // background: #ffffff;
  border-radius: 4px;
  img{
    width: 160px;
    height: 160px;
  }
  background: linear-gradient(to left, #6B5427, #6B5427) left top no-repeat, 
 linear-gradient(to bottom, #6B5427, #6B5427) left top no-repeat, 
 linear-gradient(to left,  #6B5427, #6B5427) right top no-repeat,
 linear-gradient(to bottom, #6B5427, #6B5427) right top no-repeat, 
 linear-gradient(to left, #6B5427, #6B5427) left bottom no-repeat,
 linear-gradient(to bottom, #6B5427, #6B5427) left bottom no-repeat,
 linear-gradient(to left, #6B5427, #6B5427) right bottom no-repeat,
 linear-gradient(to left, #6B5427, #6B5427) right bottom no-repeat;
   /*设置大小*/
   background-size: 2px 30px, 30px 2px, 2px 30px, 30px 2px,2px 30px, 30px 2px, 2px 30px, 30px 2px;
}
.download-link-group{
  width: 160px;
  height: 208px;
  justify-content: space-around;
  margin-left: 40px;
  a{
    width: 160px;
    height: 48px;
    border-radius: 25px;
    border: 1px solid rgba(255, 191, 52, 0.3);
    img{
      max-width: 28px;
      max-height: 29px;
    }
    span{
      font-size: 14px;
      color: #FFBF34;
      margin-left: 6px;
    }
    &:hover{
      cursor: pointer;
      border: 1px solid #FFBF34;

    }
  }
}
.download-content-right{
  width: 634px;
  height: 563px;
  text-align: right;
  img{
    width: 634px;
    height: 563px;
    // margin-left: 50px;
  }
}
.home-footer{
  height: 70px;
  background: #232323;
  font-size: 12px;
  color:#9a9a9a;
  text-align: center;
  p{
    margin-bottom: 2px;
    text-align: center;
  }
}
</style>